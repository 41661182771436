interface IComponentProps {
    models: ({
        title: string;
        modelUrl: string;
    })[];
    selectedViewable: number;
    setSelectedViewable: (index: number) => void;
}

export const PanelViewablesSelector = (props: IComponentProps) => {
    const onSelectViewable = (index: number) => {

        // viewer adds and reads page parameter from url query string
        // what adds 2 problems:
        // 1) it opens page #n in another selected drawing
        // 2) if #n is more than selected drawing pages count
        // then browser browser disappears
        window.history.replaceState(null, document.title, ""); // https://stackoverflow.com/questions/824349/how-do-i-modify-the-url-without-reloading-the-page
        
        props.setSelectedViewable(index);
    }

    const viewables = props
        .models
        .map((x, i) => (<li 
            key={`forge-viewable-${i}`} 
            style={{fontWeight: i === props.selectedViewable ? "bold" : "normal", cursor: "pointer" }} 
            onClick={() => onSelectViewable(i)} >{x.title}</li>))

    return (<div className="fullheight" style={{ overflowY: "scroll", overflowX: "hidden" }}>
        <ul>
            {viewables}
        </ul>
    </div>)
}